<template>
  <div class="product-wrap service-after-sales">
    <Header is-active="service" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-after-sales"></div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="service-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont  icon-jszc"></i></div>
            <h6>技术支持</h6>
            <p>专业的技术支持工程师7x24小时待命</p>
            <p>为客户排忧解难</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont  icon-peixun"></i></div>
            <h6>客户培训</h6>
            <p>根据项目情况编写标准培训教程</p>
            <p> 为客户提供培训服务</p>
            <p>协助客户提高员工的业务水平</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont  icon-shouhou"></i></div>
            <h6>售后服务</h6>
            <p>以顾客满意的宗旨 诚信实用的原则</p>
            <p>精益求精的态度 及时敏捷的反应</p>
            <p>为客户提供周到满意的售后服务</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '售后服务,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控售后服务-方案中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.service-after-sales {
  .banner-after-sales {
    background: url("../../assets/img/service/banner2.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }

  .service-box {
    padding: 1% 0;
    display: flex;

    .td-item {
      flex: 1;

      i {
        font-size: 100px;
        color: #1890FF;
      }

      h6 {
        font-size: 30px;
        line-height: 3;
        font-weight: normal;
        color: #333333;
      }

      p {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #8C8C8C;
        text-align: center;
        line-height: 1.5;
      }

    }
  }
}

@media (max-width: 749px) {
  .service-after-sales {
    .service-box {
      .td-item {
        flex: 1;

        i {
          font-size: 40px;
          color: #1890FF;
        }

        h6 {
          font-size: 16px;
          line-height: 3;
        }

        p {
          font-size: 12px;
        }

      }
    }
  }
}

</style>
